import React,{useState} from "react";
import Menubar from "./Menubar";
import SetPrice from "./SetPrice";
import Marketer from "./Marketer";
import {Navigate, useParams} from "react-router-dom";
import Index from "./Index";
import CustomerList from "./CustomerList";
import Settings from "./Settings";
import EditInvoice from "./EditInvoice";
const Dashboard = ()=>{
    const params = useParams();
    const [showSetPrice , setShowSetPrice] = useState(false);
    const [showMarketer , setShowMarketer] = useState(false);
    const [index , setIndex] = useState(true);
    const [customerList , setCustomerList] = useState(false);
    const [setting , setSetting] = useState(false);
    let factorNumber = params.factornumber;


    return(
        <div>
            {localStorage.getItem("adminlogintoken") ? '' : <Navigate replace to="/admin/login" />}
            <Menubar
                showsetprice={setShowSetPrice}
                showmarketer={setShowMarketer}
                showindex={setIndex}
                showcustomerlist={setCustomerList}
                setting={setSetting}
            />
            {showSetPrice ? <SetPrice/> : ''}
            {showMarketer ? <Marketer/> : ''}
            {index && !!!factorNumber? <Index/> : ''}
            {customerList ? <CustomerList/> : ''}
            {setting ? <Settings/> : ''}
            {!!factorNumber ? <EditInvoice fn={factorNumber}/> : ''}
        </div>
    )
}
export default Dashboard;
