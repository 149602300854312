import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Homepage from "./Homepage";
import "./fonts/font.css"
import Dashboard from "./admin/Dashboard";
import Invoice from "./Invoice";
import AdminLogin from "./admin/AdminLogin";
import Login from "./Login";
import FinalPage from "./FinalPage";
import FactorList from "./FactorList";
import EditInvoice from "./admin/EditInvoice";
function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path="/" element={<Invoice/>} />
            <Route path="/admin" element={<Dashboard/>} />
            <Route path="/invoice" element={<Invoice/>} />
            <Route path="/editinvoice/:factornumber" element={<Dashboard/>} />
            <Route path="/admin/login" element={<AdminLogin/>} />
            <Route path="/login" element={<Login/>} />
            <Route path="/prefactor/:factornumber" element={<FinalPage/>} />
            <Route path="/factor" element={<FactorList/>} />
            {/*<Route path="*" element={<Page404/>} />*/}
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
