import React,{useState,useEffect} from "react";
import axios from "axios";
import {Autocomplete, Button, CircularProgress, Dialog, Grid, Paper, styled, Table, TableBody,
    TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography
} from "@mui/material";
import ErrorMsg from "../msg/ErrorMsg";
import SuccsMsg from "../msg/SuccsMsg";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import {Link, Navigate} from "react-router-dom";
import AdminLogin from "./AdminLogin";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const SetPrice = ()=>{
    const [priceList , setPriceList] = useState([]);
    const [cityList , setCityList] = useState([]);
    const [loading , setLoading] = useState(true);

    const [city , setCity]=useState("");
    const [blade8 , setBlade8] = useState("");
    const [blade10 , setBlade10] = useState("");
    const [id , setId] = useState("");
    const [errorMsg , setErrorMsg] = useState("");
    const [succsMsg , setSuccsMsg] = useState("");
    const [updatePriceList , setUpdatePriceList] = useState(false);
    useEffect(()=>{
        const data = {
            lang : "ar"
        }
        axios.post("https://app.abuomarco.com/api/get_citylist.php",data)
            .then(response=>{
                if (response.status === 200)
                {
                    setLoading(false);
                    setCityList(response.data);
                }
                else
                {
                    setLoading(false);
                }
            })
            .catch(error=>{
                setLoading(false);
            })
    },[]);
    useEffect(()=>{
        const data = {

        }
        axios.post("https://app.abuomarco.com/api/get_pricelist.php",data)
            .then(response=>{
                if (response.status === 200)
                {
                    setLoading(false);
                    setPriceList(response.data);
                }
                else
                {
                    setLoading(false);
                }
            })
            .catch(error=>{
                setLoading(false);
            })
        setUpdatePriceList(false);
    },[updatePriceList]);

    const handle_change_city=(event:any,newValue: string)=>{
        setCity(newValue);
    }
    const handle_btn_insert = ()=>{
        setLoading(true);
        const data = {
            city : city,
            blade8 : blade8,
            blade10 : blade10,
        }
        axios.post("https://app.abuomarco.com/api/insert_price.php",data)
            .then(response=>{
                if (response.status === 200)
                {
                    if (response.data.error === false)
                    {
                        setLoading(false);
                        setSuccsMsg(response.data.message);
                        setUpdatePriceList(true);
                        clear();
                    }
                    else
                    {
                        setLoading(false);
                        setErrorMsg(response.data.message);
                    }

                }
                else
                {
                    setLoading(false);
                    setErrorMsg(response.data.message);
                }
            })
            .catch(error=>{
                setLoading(false);
                setErrorMsg("خطا در ارتباط با سرور . لطفاً اینترنت خود را بررسی کنید");
            })
    }
    const handle_icon_edit=(city,blade8,blade10,id)=>{
        setCity(city);
        setBlade10(blade10);
        setBlade8(blade8);
        setId(id);
    }
    const handle_btn_edit=()=>{
        setLoading(true);
        const data = {
            city : city,
            blade8 : blade8,
            blade10 : blade10,
            id : id,
            type : "edit"
        }
        axios.post("https://app.abuomarco.com/api/edit_price.php",data)
            .then(response=>{
                if (response.status === 200)
                {
                    if (response.data.error === false)
                    {
                        setLoading(false);
                        setSuccsMsg(response.data.message);
                        setUpdatePriceList(true);
                        clear();
                    }
                    else
                    {
                        setLoading(false);
                        setErrorMsg(response.data.message);
                    }

                }
                else
                {
                    setLoading(false);
                    setErrorMsg(response.data.message);
                }
            })
            .catch(error=>{
                setLoading(false);
                setErrorMsg("خطا در ارتباط با سرور . لطفاً اینترنت خود را بررسی کنید");
            })
    }
    const handle_btn_delete=(id)=>{
        setLoading(true);
        const data = {
            id : id,
            type : "delete"
        }
        axios.post("https://app.abuomarco.com/api/edit_price.php",data)
            .then(response=>{
                if (response.status === 200)
                {
                    if (response.data.error === false)
                    {
                        setLoading(false);
                        setSuccsMsg(response.data.message);
                        setUpdatePriceList(true);
                        clear();
                    }
                    else
                    {
                        setLoading(false);
                        setErrorMsg(response.data.message);
                    }

                }
                else
                {
                    setLoading(false);
                    setErrorMsg(response.data.message);
                }
            })
            .catch(error=>{
                setLoading(false);
                setErrorMsg("خطا در ارتباط با سرور . لطفاً اینترنت خود را بررسی کنید");
            })
    }
    function clear(){
        setId("");
        setCity("");
        setBlade8("");
        setBlade10("");
    }
    return(
        <div style={{direction:"rtl"}}>
            {localStorage.getItem("adminlogintoken") ? '' : <Navigate replace to="/login" />}
            {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : '' }
            {succsMsg !== '' ? <SuccsMsg message={succsMsg} openn={true} setsuccsmsg={setSuccsMsg} /> : '' }
            <Paper sx={{margin:3,padding:2}} elevation={2}>
                <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={4}>
                        <div style={{marginBottom:10}}>
                            <Typography sx={{marginBottom:1,color:"#8a8a8a",fontSize:"13px"}}>
                                نام شهر
                            </Typography>
                            <Autocomplete
                                disablePortal
                                options={cityList}
                                fullWidth
                                value={city}
                                inputValue={city}
                                onInputChange={handle_change_city}
                                renderInput={(params) => <TextField {...params} placeholder="شهر"/>}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div style={{marginBottom:10}}>
                            <Typography sx={{marginBottom:1,color:"#8a8a8a",fontSize:"13px"}}>
                                قیمت تیغه 8
                            </Typography>
                            <TextField
                                placeholder
                                fullWidth
                                type="number"
                                value={blade8}
                                onChange={(event)=>{setBlade8(event.target.value)}}
                                autoComplete="off"
                                variant="outlined" />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div style={{marginBottom:10}}>
                            <Typography sx={{marginBottom:1,color:"#8a8a8a",fontSize:"13px"}}>
                                قیمت تیغه 10
                            </Typography>
                            <TextField
                                placeholder
                                fullWidth
                                type="number"
                                value={blade10}
                                onChange={(event)=>{setBlade10(event.target.value)}}
                                autoComplete="off"
                                variant="outlined" />
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item>
                        <Button
                            onClick={handle_btn_insert}
                            variant="contained">افزودن</Button>
                    </Grid>
                    <Grid item>
                    <Button
                        onClick={handle_btn_edit}
                        variant="contained">ویرایش</Button>
                </Grid>
                </Grid>
            </Paper>
            <Paper elevation={3} sx={{margin:3,padding:2}}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 250,maxHeight:'80%',overflowY:'auto'}}
                    >
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="right">شهر</StyledTableCell>
                                <StyledTableCell align="right">تیغه 8</StyledTableCell>
                                <StyledTableCell align="right">تیغه 10</StyledTableCell>
                                <StyledTableCell align="left">عملیات</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        {priceList !== "" ?
                            <TableBody>
                                {priceList.map(price => (
                                    <StyledTableRow key={price.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <StyledTableCell align="right">
                                            {price.city}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row" align="right">
                                            {price.blade8}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row" align="right">
                                            {price.blade10}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row" align="left">
                                            <IconButton
                                                color="primary"
                                                onClick={()=>{handle_icon_edit(price.city,price.blade8,price.blade10,price.id)}}>
                                                <BorderColorIcon />
                                            </IconButton>
                                            <IconButton
                                                color="error"
                                                onClick={()=>{handle_btn_delete(price.id)}}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </StyledTableCell>
                                    </StyledTableRow >
                                ))}
                            </TableBody>
                            : '' }

                    </Table>
                </TableContainer>
            </Paper>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin:2}} />
            </Dialog>
        </div>

    )
}
export default SetPrice;