import React, {useState, useEffect} from "react";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {CardMedia} from "@mui/material";
import {Link} from "react-router-dom";

const pages = ['صفحه اصلی', 'پیش فاکتورها'];
const settings = ['Logout'];
const Menubar = ({showsetprice, showmarketer, showindex, showcustomerlist}) => {

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handle_btn_setting = (index) => {
        if (index === 0) {
            localStorage.clear();
        }
    }
    return (

        <AppBar position="static" sx={{direction: "rtl", backgroundColor: "#000000"}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{backgroundColor: "#000000"}}>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/admin"
                        sx={{
                            mr: 2,
                            display: {xs: 'none', md: 'flex'},
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}>
                        <CardMedia
                            component="img"
                            height="50"
                            width="60"
                            image="https://abuomarco.com/assets/img/logo.png"
                        />
                    </Typography>
                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit">
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={() => {
                                setAnchorElNav(null)
                            }}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}>
                            <MenuItem key={1} component={Link} to={"/"}>
                                <Typography textAlign="center">صفحه اصلی</Typography>
                            </MenuItem>
                            <MenuItem key={1} component={Link} to={"/factor"}>
                                <Typography textAlign="center">پیش فاکتور ها</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/admin"
                        sx={{
                            mr: 2,
                            display: {xs: 'flex', md: 'none'},
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}>
                        <CardMedia
                            component="img"
                            sx={{height: "50px", width: "60px !important"}}
                            image="https://abuomarco.com/assets/img/logo.png"
                        />
                    </Typography>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        <Button
                            component={Link}
                            to="/"
                            key={1}
                            sx={{my: 2, color: 'white', display: 'block'}}>
                            صفحه اصلی
                        </Button>
                        <Button
                            component={Link}
                            to="/factor"
                            key={1}
                            sx={{my: 2, color: 'white', display: 'block'}}>
                            پیش فاکتور ها
                        </Button>
                    </Box>
                    <Box sx={{flexGrow: 0}}>
                        <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                            <Avatar alt="Admin" src="/static/images/avatar/2.jpg"/>
                        </IconButton>
                        <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}>
                            {settings.map((setting, index) => (
                                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                    <Typography component="a" href="/login" onClick={() => {
                                        handle_btn_setting(index)
                                    }} textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}
export default Menubar;