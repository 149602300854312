import React, {useState, useEffect} from "react";
import bg from "./bg.jpg";
import {Card, CardContent, CircularProgress, Dialog, Stack, Typography} from "@mui/material";
import Menu from "./Menu";
import {Navigate} from "react-router-dom";
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
const FinalPage = () => {
    const [loading, setLoading] = useState(false);
    return (
        <div id="main">
            {localStorage.getItem("logintoken") ? '' : <Navigate replace to="/login"/>}
            <Menu/>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                height: "80vh",
            }}>
                <Card sx={{minWidth: 200, padding: 2, margin: 2,backgroundColor:"rgba(0,252,0,0.14)"}} elevation={3}>
                    <CardContent sx={{textAlign:"center"}}>
                        <Stack direction={"row"}>
                            <div style={{margin:"auto",textAlign:"right"}}>
                                <Typography>
                                    پیش فاکتور شما با موفقیت صادر و در انتظار تایید مدیر می باشد
                                </Typography>
                                <Typography dir={"rtl"} sx={{marginTop:2}}>
                                    پس از تایید پیش فاکتور میتوانید از منوی "پیش فاکتور ها" نسخه PDF پیش فاکتور را دانلود نمایید
                                </Typography>
                            </div>
                            <HourglassEmptyIcon sx={{color:"rgba(0,0,0,0.12)",fontSize:"130px"}}/>
                        </Stack>


                    </CardContent>
                </Card>
                <Dialog
                    open={loading}
                    scroll="paper">
                    <CircularProgress sx={{margin: 2}}/>
                </Dialog>
            </div>
        </div>
    )
}
export default FinalPage;