import React,{useState,useEffect} from "react";
import {
    CircularProgress,
    Dialog,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import axios from "axios";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const CustomerList =()=>{
    const [customerList , setCustomerList] = useState([]);
    const [loading , setLoading] = useState(true);
    useEffect(()=>{
        axios.post("https://app.abuomarco.com/api/get_customerlist.php")
            .then(response=>{
                if (response.status===200){
                    setCustomerList(response.data);
                    setLoading(false);
                }
            })
            .catch(error=>{
                setLoading(false);
            })
    })
    return (
        <div>
            <Paper elevation={3} sx={{margin:3,padding:2}}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 250,maxHeight:'80%',overflowY:'auto'}} dir="rtl">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="right">نام و نام خانوادگی</StyledTableCell>
                                <StyledTableCell align="right">شماره تلفن</StyledTableCell>
                                <StyledTableCell align="right">بازاریاب</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        {customerList !== "" ?
                            <TableBody>
                                {customerList.map(list => (
                                    <StyledTableRow key={list.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <StyledTableCell align="right">
                                            {list.name}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row" align="right">
                                            {list.number}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row" align="right">
                                            {list.marketer}
                                        </StyledTableCell>
                                    </StyledTableRow >
                                ))}
                            </TableBody>
                            : '' }

                    </Table>
                </TableContainer>
            </Paper>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin:2}} />
            </Dialog>
        </div>
    )
}
export default CustomerList;