import React,{useState,useEffect} from "react";
import axios from "axios";
import ErrorMsg from "../msg/ErrorMsg";
import SuccsMsg from "../msg/SuccsMsg";
import {
    Button, CircularProgress, Dialog, Grid, Paper, styled, Table, TableBody, TableCell, tableCellClasses,
    TableContainer, TableHead, TableRow, TextField, Typography
} from "@mui/material";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import IconButton from "@mui/material/IconButton";
import {Link, Navigate} from "react-router-dom";
import AdminLogin from "./AdminLogin";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const Marketer = ()=>{

    const [name , setName] = useState("");
    const [number , setNumber] = useState("");
    const [code , setCode] = useState("");
    const [password , setPassword] = useState("");
    const [marketerList , setMarketerList] = useState([]);
    const [loading ,  setLoading] = useState(false);
    const [update , setUpdate] = useState(false);
    const [errorMsg ,setErrorMsg] = useState("");
    const [succsMsg , setSuccsMsg] = useState("");
    const [selecttedMarketer , setSelectedMarketer] = useState("");
    const handle_btn_createMarketer =()=>{
        setLoading(true);
        const data = {
            number : number,
            name : name,
            code : code ,
            password : password
        }
        axios.post("https://app.abuomarco.com/api/insert_marketer.php",data)
            .then(response=>{
                if (response.status === 200)
                {
                    if (response.data.error === false) {
                        setLoading(false);
                        setSuccsMsg(response.data.message);
                        setUpdate(true);
                        clear();
                    }
                    else {
                        setLoading(false);
                        setErrorMsg(response.data.message);
                    }
                }
                else
                {
                    setLoading(false);
                    setErrorMsg("خطا در ارتباط با سرور");
                }
            })
            .catch(error=>{
                setErrorMsg("خطا در ارتباط با سرور");
                setLoading(false);
            })
    }
    useEffect(()=>{
        setLoading(true);
        const data = {

        }
        axios.post("https://app.abuomarco.com/api/get_marketerlist.php",data)
            .then(response=>{
                if (response.status === 200)
                {
                    setLoading(false);
                    setMarketerList(response.data);
                }
                else
                {
                    setLoading(false);
                }
            })
            .catch(error=>{
                setLoading(false);
            })
        setUpdate(false);
    },[update])
    const handle_btn_changeMarketer = ()=>{
        if (selecttedMarketer !== ""){
            setLoading(true);
            const data = {
                number : number,
                name : name,
                code : code ,
                password : password
            }
            axios.post("https://app.abuomarco.com/api/edit_marketer.php",data)
                .then(response=>{
                    if (response.status === 200)
                    {
                        if (response.data.error === false) {
                            setLoading(false);
                            setSuccsMsg(response.data.message);
                            setUpdate(true);
                            clear();
                        }
                        else {
                            setLoading(false);
                            setErrorMsg(response.data.message);
                        }
                    }
                    else
                    {
                        setLoading(false);
                        setErrorMsg("خطا در ارتباط با سرور");
                    }
                })
                .catch(error=>{
                    setErrorMsg("خطا در ارتباط با سرور");
                    setLoading(false);
                })
        }
        else {
            setErrorMsg("هیچ بازاریابی انتخاب نشده است");
        }
    }
    const handle_btn_edit = (name , number , code)=>{
        setName(name);
        setNumber(number);
        setCode(code);
        setPassword("");
        window.scrollTo(0, 0);
        setSelectedMarketer(code);
    }
    const handle_btn_delete=()=>{
        if (selecttedMarketer !== ""){
            setLoading(true);
            const data = {
                code : code ,
            }
            axios.post("https://app.abuomarco.com/api/delete_marketer.php",data)
                .then(response=>{
                    if (response.status === 200)
                    {
                        if (response.data.error === false) {
                            setLoading(false);
                            setSuccsMsg(response.data.message);
                            setUpdate(true);
                            clear();
                        }
                        else {
                            setLoading(false);
                            setErrorMsg(response.data.message);
                        }
                    }
                    else
                    {
                        setLoading(false);
                        setErrorMsg("خطا در ارتباط با سرور");
                    }
                })
                .catch(error=>{
                    setErrorMsg("خطا در ارتباط با سرور");
                    setLoading(false);
                })
        }
        else {
            setErrorMsg("هیچ بازاریابی انتخاب نشده است");
        }
    }
    function clear(){
        setPassword("");
        setName("");
        setNumber("");
        setCode("");
        setSelectedMarketer("");
    }
    return(
        <div style={{direction:"rtl"}}>
            {localStorage.getItem("adminlogintoken") ? '' : <Navigate replace to="/login" />}
            {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : '' }
            {succsMsg !== '' ? <SuccsMsg message={succsMsg} openn={true} setsuccsmsg={setSuccsMsg} /> : '' }
            <Paper sx={{margin:3,padding:2}} elevation={3}>
                <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={4}>
                        <div style={{marginBottom:0}}>
                            <Typography sx={{marginBottom:1,color:"#8a8a8a",fontSize:"13px"}}>
                                نام و نام خانوادگی
                            </Typography>
                            <TextField
                                placeholder
                                fullWidth
                                value={name}
                                onChange={(event)=>{setName(event.target.value)}}
                                autoComplete="off"
                                variant="outlined" />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div style={{marginBottom:0}}>
                            <Typography sx={{marginBottom:1,color:"#8a8a8a",fontSize:"13px"}}>
                                شماره موبایل
                            </Typography>
                            <TextField
                                placeholder
                                fullWidth
                                type="number"
                                value={number}
                                onChange={(event)=>{setNumber(event.target.value)}}
                                autoComplete="off"
                                variant="outlined" />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div style={{marginBottom:0}}>
                            <Typography sx={{marginBottom:1,color:"#8a8a8a",fontSize:"13px"}}>
                                کد انحصاری بازاریاب (در عملیات ویرایش قابل تغییر نیست)
                            </Typography>
                            <TextField
                                placeholder
                                fullWidth
                                value={code}
                                onChange={(event)=>{setCode(event.target.value)}}
                                autoComplete="off"
                                variant="outlined" />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div style={{marginBottom:20}}>
                            <Typography sx={{marginBottom:1,color:"#8a8a8a",fontSize:"13px"}}>
                                رمز عبور
                            </Typography>
                            <TextField
                                placeholder
                                fullWidth
                                type="password"
                                value={password}
                                onChange={(event)=>{setPassword(event.target.value)}}
                                autoComplete="off"
                                variant="outlined" />
                        </div>
                    </Grid>
                </Grid>
                <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item>
                        <Button onClick={handle_btn_createMarketer} variant="contained">ایجاد بازاریاب جدید</Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={handle_btn_changeMarketer} color="info" variant="contained">ویرایش اطلاعات</Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={handle_btn_delete} color="error" variant="contained">حذف بازاریاب</Button>
                    </Grid>
                </Grid>
            </Paper>
            <Paper elevation={3} sx={{margin:3,padding:2}}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 250,maxHeight:'80%',overflowY:'auto'}}
                    >
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="right">نام و نام خانوادگی</StyledTableCell>
                                <StyledTableCell align="right">شماره تلفن</StyledTableCell>
                                <StyledTableCell align="right">کد بازاریاب</StyledTableCell>
                                <StyledTableCell align="left">عملیات</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        {marketerList !== "" ?
                            <TableBody>
                                {marketerList.map(list => (
                                    <StyledTableRow key={list.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <StyledTableCell align="right">
                                            {list.name}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row" align="right">
                                            {list.number}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row" align="right">
                                            {list.code}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row" align="left">
                                            <IconButton
                                                color="primary"
                                                onClick={()=>{handle_btn_edit(list.name,list.number,list.code)}}>
                                                <BorderColorIcon />
                                            </IconButton>
                                        </StyledTableCell>
                                    </StyledTableRow >
                                ))}
                            </TableBody>
                            : '' }

                    </Table>
                </TableContainer>
            </Paper>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin:2}} />
            </Dialog>
        </div>
    )
}
export default Marketer;