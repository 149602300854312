import React, {useState, useEffect} from "react";
import {Button, CircularProgress, Dialog, Paper, TextField, Typography} from "@mui/material";
import axios from "axios";
import ErrorMsg from "../msg/ErrorMsg";
import SuccsMsg from "../msg/SuccsMsg";

const Settings = () => {
    const [loading, setLoading] = useState(true);
    const [bottomText, setBottomText] = useState("");
    const [errorMsg , setErrorMsg] = useState("");
    const [succsMsg , setSuccsMsg] = useState("");
    useEffect(() => {
        const data = {
            token: localStorage.getItem("adminlogintoken"),
        }
        axios.post("https://app.abuomarco.com/api/get_settings.php",data)
            .then(response => {
                setLoading(false);
                if (response.status === 200) {
                    if (response.data.error === false){
                        setBottomText(response.data.bottomtext);
                    }
                }
            })
            .catch(error => {
                setLoading(false);
            })
    },[])
    const handle_btn_savesetting = () => {
        setLoading(true);
        const data = {
            token: localStorage.getItem("adminlogintoken"),
            bottomtext : bottomText,
        }
        axios.post("https://app.abuomarco.com/api/set_settings.php",data)
            .then(response => {
                setLoading(false);
                if (response.status === 200) {
                    if (response.data.error === false){
                        setSuccsMsg(response.data.message);
                    }
                    else
                        setErrorMsg(response.date.message);

                }
            })
            .catch(error => {
                setLoading(false);
            })
    }
    return (
        <div dir={"rtl"}>
            {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : '' }
            {succsMsg !== '' ? <SuccsMsg message={succsMsg} openn={true} setsuccsmsg={setSuccsMsg} /> : '' }
            <Paper elevation={3} sx={{margin: 3, padding: 2}}>
                <Typography>
                    متن پایین PDF ها
                </Typography>
                <TextField
                    fullWidth
                    multiline
                    value={bottomText}
                    onChange={(e)=>setBottomText(e.target.value)}
                    maxRows={4}
                    variant="filled"
                    sx={{marginBottom: 1}}
                />
                <Button
                    onClick={handle_btn_savesetting}
                    variant="contained">ویرایش</Button>
            </Paper>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin: 2}}/>
            </Dialog>
        </div>

    )
}
export default Settings;