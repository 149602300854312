import React,{useEffect,useState} from "react";
import {Button, Card, CardActions, CardContent, CardMedia, CircularProgress, Dialog, TextField, Typography
} from "@mui/material";
import {Navigate} from "react-router-dom";
import ErrorMsg from "../msg/ErrorMsg";
import axios from "axios";

const AdminLogin = ()=>{
    const [errorMsg ,setErrorMsg] = useState("");
    const [username , setUsername] = useState("");
    const [password , setPassword] = useState("");
    const [loading , setLoading] = useState(false);
    const [login , setLogin] = useState(false);
    const handle_btn_login=()=>{
        setLoading(true);
        const data={
            username : username,
            password : password,
        }
        axios.post("https://app.abuomarco.com/api/adminlogin.php",data)
            .then(response=>{
                if (response.status === 200) {
                    setLoading(false);
                    if (response.data.error === false) {
                        localStorage.setItem("adminlogin", true);
                        localStorage.setItem("adminlogintoken", response.data.token);
                        setLogin(true);
                    } else
                        setErrorMsg(response.data.message);
                } else {
                    setLoading(false);
                    setErrorMsg("Connection is lost");
                }
            })
            .catch(error=>{
                setErrorMsg("خطا در ارتباط با سرور");
            })
    }
    const Redirect = ()=>{
        return (<Navigate replace to="/admin" />)
    }
    return(
        <div style={{display:"flex" ,justifyContent:"center"}}>
            {login ? <Redirect/> : ''}
            {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : '' }
            <Card sx={{ minWidth: 300 }}>
                <CardContent>
                    <CardMedia
                        component="img"
                        height="194"
                        image="https://abuomarco.com/assets/img/final-logo.png"
                    />
                    <Typography variant="h5" component="div">

                    </Typography>
                    <Typography sx={{ mb: 1.5,marginTop:2 }} color="text.secondary">
                        Login
                    </Typography>
                    <Typography variant="body2">
                        <TextField
                            placeholder={"username"}
                            fullWidth
                            type="text"
                            autoComplete="off"
                            onChange={(event)=>{setUsername(event.target.value)}}
                            variant="outlined" />
                        <TextField
                            placeholder={"password"}
                            fullWidth
                            type="password"
                            autoComplete="off"
                            onChange={(event)=>{setPassword(event.target.value)}}
                            sx={{marginTop:1}}
                            variant="outlined" />
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button onClick={handle_btn_login} size="small">Login</Button>
                </CardActions>
            </Card>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin:2}} />
            </Dialog>
        </div>
    )
}
export default AdminLogin;