import React, {useState, useEffect} from "react";
import {
    Box,
    Button, Card, CardActions,
    CardContent, Chip,
    CircularProgress,
    Dialog,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import axios from "axios";
import ErrorMsg from "../msg/ErrorMsg";
import SuccsMsg from "../msg/SuccsMsg";
import {Link} from "react-router-dom";

const Index = () => {
    const [type, setType] = useState("0");
    const [factorNumber, setFactorNumber] = useState("");
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState(false);
    const [factorList, setFactorList] = useState([]);
    const [errorMsg, setErrorMsg] = useState("");
    const [succsMsg, setSuccsMsg] = useState("");
    const [update, setUpdate] = useState(false);
    useEffect(() => {
        axios.post("https://app.abuomarco.com/api/get_unverifyfactor.php")
            .then(response => {
                if (response.status === 200) {
                    setFactorList(response.data);
                    setLoading(false);
                }
            })
            .catch(error => {
                setLoading(false);
            })
        setUpdate(false);
    }, [update])
    const handle_btn_search = () => {
        setLoading(true);
        setFactorNumber(false);
        const data = {
            type: type,
            search: search
        }
        axios.post("https://app.abuomarco.com/api/search.php", data)
            .then(response => {
                if (response.status === 200) {
                    if (response.data.error !== true) {
                        setLoading(false);
                        setFactorNumber(response.data.factornumber);
                    } else {
                        setLoading(false);
                        setFactorNumber(false);
                    }
                }
            })
            .catch(error => {
                setLoading(false);
            })
    }
    const handle_btn_verify = (factornumber) => {
        setLoading(true);
        const data = {
            token: localStorage.getItem("adminlogintoken"),
            factornumber: factornumber,
        }
        axios.post("https://app.abuomarco.com/api/set_verifyfactor.php", data)
            .then(response => {
                setLoading(false);
                if (response.status === 200) {
                    if (response.data.error === false) {
                        setSuccsMsg(response.data.message);
                        setUpdate(true);
                    } else
                        setErrorMsg(response.date.message);

                }
            })
            .catch(error => {
                setLoading(false);
            })
    }
    return (
        <div>
            {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : ''}
            {succsMsg !== '' ? <SuccsMsg message={succsMsg} openn={true} setsuccsmsg={setSuccsMsg}/> : ''}
            <Box sx={{width: "80%", margin: "auto", direction: "rtl", marginTop: 4}}>
                <Grid container spacing={2}>
                    <Grid item xs={9} sx={{display: "flex", justifyContent: "center",}}>
                        <TextField
                            placeholder={"جست و جو"}
                            fullWidth
                            type="text"
                            onChange={(event) => {
                                setSearch(event.target.value)
                            }}
                            autoComplete="off"
                            variant="outlined"/>
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            value={type}
                            fullWidth
                            onChange={(event) => {
                                setType(event.target.value)
                            }}
                        >
                            <MenuItem value={0}>شماره فاکتور</MenuItem>
                            <MenuItem value={1}>شماره مشتری</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                <Button sx={{marginTop: 3}} onClick={handle_btn_search} fullWidth variant="contained" size="medium">جست
                    و جو</Button>
                {factorNumber ?
                    <Button
                        sx={{marginTop: 3}}
                        compunent="a"
                        href={"https://app.abuomarco.com/pdf/index.php?fnumber=" + factorNumber}
                        fullWidth
                        variant="outlined"
                        size="medium">دانلود فاکتور</Button>
                    :
                    ""
                }
                <div style={{marginTop: "30px"}}>لیست پیش فاکتور های تایید نشده</div>
                {factorList.map((items) => (
                    <Card sx={{marginTop: 1}}>
                        <CardContent>
                            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                تایید فاکتور شماره : {items.factornumber}
                            </Typography>
                            <Typography variant="h6" component="div">
                                نام بازاریاب : {items.marketername} , کد بازاریاب {items.marketerid} , نام مشتری
                                : {items.customername}
                            </Typography>
                            <Typography sx={{mb: 2, marginRight: 2, marginTop: 1}} color="text.secondary">
                                دهنه های فاکتور
                            </Typography>
                            <Typography variant="body2" sx={{marginRight: 3}}>
                                {items.factoritems.map((items) => (
                                    <Grid container sx={{marginBottom: 1}} spacing={2}>
                                        <Grid item xs={12}>
                                            {"تیغه " + items.blade + " عرض : " + items.width + " , ارتفاع :" + items.height + " , رنگ :" + items.color + " , قیمت هر مترمربع :" + items.price}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button
                                size="small"
                                onClick={() => handle_btn_verify(items.factornumber)}>
                                تایید پیش فاکتور
                            </Button>
                            <Link to={"/editinvoice/" + items.factornumber}>
                                <Button
                                    size="small"
                                    onClick>
                                    ویرایش پیش فاکتور
                                </Button>
                            </Link>
                        </CardActions>
                    </Card>
                ))}
            </Box>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin: 2}}/>
            </Dialog>
        </div>
    )
}
export default Index;