import React, {useState, useEffect} from "react";
import {
    Autocomplete, Box, Button, ButtonGroup, Checkbox, Chip, CircularProgress, Dialog, FormControl, FormControlLabel,
    Grid, MenuItem, Paper, Radio, RadioGroup, Select, TextField, Typography
} from "@mui/material";
import Font from "../fonts/font.css"
import axios from "axios";
import ErrorMsg from "../msg/ErrorMsg";
import SuccsMsg from "../msg/SuccsMsg";
import {isRouteErrorResponse, Navigate} from "react-router-dom";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const EditInvoice = ({fn}) => {
    const [cityList, setCityList] = useState([]);
    const [checkwirelesskey, setCheckwirelesskey] = useState(false);
    const [checkremote, setCheckremote] = useState(false);
    const [checkmotorcover, setCheckmotorcover] = useState(false);
    const [checkmotor, setCheckmotor] = useState(false);
    const [checkgsmreciver, setCheckreciver] = useState(false);
    const [factorNumber, setFactorNumber] = useState(fn);
    const [txtBalde, setTxtBalde] = useState("");
    const [city, setCity] = useState("");
    const [height, setHeight] = useState("");
    const [width, setWidth] = useState("");
    const [txtWirelessKey, setTxtWirelessKey] = useState("0");
    const [txtSensor, setTxtSensor] = useState("2");
    const [txtRemote, setTxtRemote] = useState("0");
    const [txtGsm, setTxtGsm] = useState("0");
    const [txtMotor, setTxtMotor] = useState("0");
    const [txtMotorCover, setTxtMotorCover] = useState("0");
    const [marketerName, setMarketerName] = useState("");
    const [marketerId, setMarketerId] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [customerTel, setCustomerTel] = useState("");
    const [color, setColor] = useState("");
    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState("");
    const [succsMsg, setSuccsMsg] = useState("");
    const [scroll, setScroll] = useState(false);
    const [bitList, setBitList] = useState([]);
    const [updateBits, setUpdateBits] = useState(false);
    const [price, setPrice] = useState("");
    const [step , setStep] = useState(1);
    useEffect(() => {
        const data = {
            lang: "ar"
        }
        axios.post("https://app.abuomarco.com/api/get_citylist.php", data)
            .then(response => {
                if (response.status === 200) {
                    setLoading(false);
                    setCityList(response.data);
                } else {
                    setLoading(false);
                }
            })
            .catch(error => {
                setLoading(false);
            })
    }, []);
    useEffect(() => {
        const data = {
            token: localStorage.getItem("logintoken"),
            factornumber : factorNumber,
        }
        axios.post("https://app.abuomarco.com/api/get_factoritem.php", data)
            .then(response => {
                if (response.status === 200) {
                    setFactorNumber(response.data.factornumber);
                    setMarketerName(response.data.marketername);
                    setMarketerId(response.data.marketerid);
                    setCustomerName(response.data.customername);
                    setCustomerTel(response.data.customertel);
                    setCity(response.data.city);
                    setTxtMotor(response.data.motor);
                    if (parseInt(response.data.motor) > 0)
                        setCheckmotor(true);
                    setTxtRemote(response.data.remote);
                    if (!!response.data.remote)
                        setCheckremote(true);
                    setTxtWirelessKey(response.data.wirelesskey);
                    if (parseInt(response.data.wirelesskey) >0)
                        setCheckwirelesskey(true);
                    setTxtMotorCover(response.data.motorcover);
                    if (parseInt(response.data.motorcover) > 0)
                        setCheckmotorcover(true);
                    setTxtGsm(response.data.gsm);
                    if (parseInt(response.data.gsm)>0)
                        setCheckreciver(true);

                } else {
                    setLoading(false);
                }
            })
            .catch(error => {
                setLoading(false);
            })
    }, []);
    useEffect(() => {
        const data = {
            token: localStorage.getItem("logintoken"),
            factornumber: factorNumber,
        }
        axios.post("https://app.abuomarco.com/api/get_bitlist.php", data)
            .then(response => {
                if (response.status === 200) {
                    setBitList(response.data);
                }
            })
            .catch(error => {
                setLoading(false);
            })
        setUpdateBits(false);
    }, [updateBits]);
    const handle_change_checkwireless = (event) => {
        setCheckwirelesskey(event.target.checked);
        if (!event.target.checked)
            setTxtWirelessKey("0");
    }
    const handle_change_checkgsmreciver = (event) => {
        setCheckreciver(event.target.checked);
        if (!event.target.checked)
            setTxtGsm("0");
    }
    const handle_change_checkmotor = (event) => {
        setCheckmotor(event.target.checked);
        if (!event.target.checked)
            setTxtMotor("0");
    }
    const handle_change_checkmotorcover = (event) => {
        setCheckmotorcover(event.target.checked);
        if (!event.target.checked)
            setTxtMotorCover("0");
    }
    const handle_change_checkremote = (event) => {
        setCheckremote(event.target.checked);
        if (!event.target.checked)
            setTxtRemote("0");
    }
    const handle_txtblade_change = (event) => {
        setTxtBalde(event.target.value);
    }
    const handle_change_city = (event: any, newValue: string) => {
        setCity(newValue);
    }
    useEffect(() => {
        if (!loading)
            scrollToTop();
        setScroll(false);
    }, [scroll]);
    const handle_change_color = (event) => {
        setColor(event.target.value);
    }
    const handle_btn_createpdf = () => {
        setLoading(true);
        const data = {
            customername: customerName,
            customertel: customerTel,
            city: city,
            sensor: txtSensor,
            remote: txtRemote,
            wirelesskey: txtWirelessKey,
            gsm: txtGsm,
            motor: txtMotor,
            motorcover: txtMotorCover,
            fnumber: factorNumber,
            marketerid: marketerId,
        }
        axios.post("https://app.abuomarco.com/api/update_factor.php", data)
            .then(response => {
                if (response.status === 200) {
                    if (response.data.error === false) {
                        setLoading(false);
                        setSuccsMsg(response.data.message);
                        window.open("/admin", "_parent", '', '');
                        //window.open('https://app.abuomarco.com/pdf/index.php?fnumber=' + response.data.factornumber, '_blank', '', '');
                        clear();
                    } else if (response.data.status === 2) {
                        setLoading(false);
                        setErrorMsg(response.data.message);
                    } else {
                        setLoading(false);
                        setErrorMsg(response.data.message);
                    }
                } else {
                    setLoading(false);
                    setErrorMsg("خطا در ارتباط با سرور");
                }
            })
            .catch(error => {
                setLoading(false);
                setErrorMsg("خطا در ارتباط با سرور");
            })
    }
    const handle_btn_addtofactor = () => {
        setLoading(true);
        const data = {
            width: width,
            height: height,
            color: color,
            txtBalde: txtBalde,
            fnumber: factorNumber,
            marketername: marketerName,
            marketerid: marketerId,
            price: price,
        }
        axios.post("https://app.abuomarco.com/api/insert_factor.php", data)
            .then(response => {
                if (response.status === 200) {
                    if (response.data.error === false) {
                        setLoading(false);
                        setSuccsMsg(response.data.message);
                        setWidth("");
                        setHeight("");
                        setColor("");
                        setUpdateBits(true);
                        setPrice("");
                        setFactorNumber(response.data.factornumber);
                    } else if (response.data.status === 2) {
                        setLoading(false);
                        setErrorMsg(response.data.message);
                    } else {
                        setLoading(false);
                        setErrorMsg(response.data.message);
                    }
                } else {
                    setLoading(false);
                    setErrorMsg("خطا در ارتباط با سرور");
                }
            })
            .catch(error => {
                setLoading(false);
                setErrorMsg("خطا در ارتباط با سرور");
            })
    }

    function clear() {
        setColor("");
        setCheckmotor(false);
        setCheckmotorcover(false);
        setCheckreciver(false);
        setCheckremote(false);
        setCheckwirelesskey(false);
        setCity("");
        setHeight("");
        setWidth("");
        setTxtGsm(0);
        setTxtMotor(0);
        setTxtWirelessKey(0);
        setTxtSensor(0);
        setTxtRemote(0);
        setTxtMotorCover(0);
    }

    const scrollToTop = () => {
        document.getElementById('main')?.scrollIntoView({behavior: 'smooth'});
    }
    const handle_btn_deletebit_click = (id) => {
        setLoading(true);
        const data = {
            id: id,
        }
        axios.post("https://app.abuomarco.com/api/delete_bit.php", data)
            .then(response => {
                setLoading(false);
                if (response.status === 200) {
                    if (response.data.error === false) {
                        setSuccsMsg(response.data.message);
                        setUpdateBits(true);
                    } else
                        setErrorMsg(response.data.message);
                }
            })
            .catch(error => {
                setLoading(false);
            })
    }
    return (
        <div id="main" style={{paddingBottom:"10px"}}>
            {localStorage.getItem("logintoken") ? '' : <Navigate replace to="/login"/>}
            {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : ''}
            {succsMsg !== '' ? <SuccsMsg message={succsMsg} openn={true} setsuccsmsg={setSuccsMsg}/> : ''}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "rows",
                    marginTop: 2,
                    direction: "rtl",
                    paddingRight:2,
                    paddingLeft:2,
                    minHeight:"88vh",
                    height:"100%"
                }}>
                <Paper elevation={3}
                       style={{paddingLeft: "20px", paddingRight: "20px", minWidth: "95%", maxWidth: "700px"}}>
                    <Grid container sx={{marginBottom: 1}} spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="h6" component="h6" sx={{marginBottom: 2, marginTop: 2}}>
                                ویرایش پیش فاکتور شماره {factorNumber}
                            </Typography>
                        </Grid>
                    </Grid>
                    <div id={"step1"} style={{display: step===1 ? "" : "none"}}>
                        <div style={{marginBottom: 10}}>
                            <Typography sx={{marginBottom: 1, color: "#8a8a8a", fontSize: "13px"}}>
                                نام و نام خانوادگی بازاریاب
                            </Typography>
                            <Typography sx={{
                                marginBottom: 1,
                                color: "#000000",
                                fontSize: "18px",
                                marginRight: 2,
                                fontWeight: "bold"
                            }}>
                                {marketerName}
                            </Typography>
                        </div>
                        <Grid container sx={{marginBottom: 1}} spacing={2}>
                            <Grid item xs={12}>
                                <div>
                                    <Typography sx={{marginBottom: 1, color: "#8a8a8a", fontSize: "13px"}}>
                                        لطفاً نام مشتری را وارد کنید
                                    </Typography>
                                    <TextField
                                        placeholder="نام مشتری"
                                        fullWidth
                                        value={customerName}
                                        onChange={(event) => {
                                            setCustomerName(event.target.value)
                                        }}
                                        autoComplete="off"
                                        variant="outlined"/>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container sx={{marginBottom: 1}} spacing={2}>
                            <Grid item xs={12}>
                                <div>
                                    <Typography sx={{marginBottom: 1, color: "#8a8a8a", fontSize: "13px"}}>
                                        لطفاً شماره تماس مشتری را وارد کنید
                                    </Typography>
                                    <TextField
                                        placeholder="شماره تماس مشتری"
                                        fullWidth
                                        value={customerTel}
                                        onChange={(event) => {
                                            setCustomerTel(event.target.value)
                                        }}
                                        autoComplete="off"
                                        variant="outlined"/>
                                </div>
                            </Grid>
                        </Grid>
                        <div style={{marginBottom: 10}}>
                            <Typography sx={{marginBottom: 1, color: "#8a8a8a", fontSize: "13px"}}>
                                لطفاً نام شهر خود را وارد کنید
                            </Typography>
                            <Autocomplete
                                disablePortal
                                options={cityList}
                                value={city}
                                fullWidth
                                onInputChange={handle_change_city}
                                sx={{direction: "rtl"}}
                                renderInput={(params) => <TextField {...params} placeholder="شهر"/>}
                            />
                        </div>
                        <div>
                            <Grid container sx={{marginBottom: 1}} spacing={2}>
                                <Grid item xs={6}>
                                    <Button
                                        sx={{marginBottom: 2}}
                                        onClick={()=>setStep(step+1)}
                                        variant="contained">
                                        <ArrowForwardIcon/>
                                        {" مرحله بعدی "}
                                    </Button>
                                </Grid>
                                <Grid item xs={6} textAlign={"left"}>
                                    <Button
                                        dir={"ltr"}
                                        sx={{marginBottom: 2}}
                                        disabled
                                        variant="contained">
                                        <ArrowBackIcon/>
                                        {" مرحله قبلی "}
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>

                    <div id={"step2"} style={{display: step===2 ? "" : "none"}}>
                        {bitList.map((items) => (
                            <Grid container sx={{marginBottom: 1}} spacing={2}>
                                <Grid item xs={12}>
                                    <Chip
                                        dir={"ltr"}
                                        label={"تیغه " + items.blade + " عرض : " + items.width + " , ارتفاع :" + items.height + " , رنگ :" + items.color + " , قیمت هر مترمربع :" + items.price}
                                        onDelete={() => handle_btn_deletebit_click(items.id)}
                                    />
                                </Grid>
                            </Grid>
                        ))}

                        <div style={{marginBottom: 10}}>

                            <Typography sx={{marginBottom: 1, color: "#8a8a8a", fontSize: "13px"}}>
                                لطفاً تیغه مدنظر خود را انتخاب کنید
                            </Typography>
                            <RadioGroup
                                row
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        fontSize: 28,
                                    },
                                }}>
                                <FormControlLabel
                                    onChange={handle_txtblade_change}
                                    value="8" control={<Radio/>} label="8 سانتیمتری"/>
                                <FormControlLabel
                                    onChange={handle_txtblade_change}
                                    value="10" control={<Radio/>} label="10 سانتیمتری"/>
                            </RadioGroup>
                        </div>
                        <div style={{marginBottom: 10}}>
                            <Typography sx={{marginBottom: 1, color: "#8a8a8a", fontSize: "13px"}}>
                                لطفاً رنگ تیغه را انتخاب کنید
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    value={color}
                                    onChange={handle_change_color}>
                                    <MenuItem sx={{direction: "rtl"}} value="White">سفید</MenuItem>
                                    <MenuItem sx={{direction: "rtl"}} value="Black">سیاه</MenuItem>
                                    <MenuItem sx={{direction: "rtl"}} value="Gray">طوسی</MenuItem>
                                    <MenuItem sx={{direction: "rtl"}} value="Brown">قهوه ای</MenuItem>
                                    <MenuItem sx={{direction: "rtl"}} value="Wormy">کرم</MenuItem>
                                    <MenuItem sx={{direction: "rtl"}} value="Wood design">طرح چوب</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <Grid container sx={{marginBottom: 1}} spacing={2}>
                            <Grid item xs={12}>
                                <div>
                                    <TextField
                                        placeholder="عرض درب (به سانتی متر وارد شود)"
                                        fullWidth
                                        value={width}
                                        onChange={(event) => {
                                            setWidth(event.target.value)
                                        }}
                                        autoComplete="off"
                                        variant="outlined"/>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container sx={{marginBottom: 1}} spacing={2}>
                            <Grid item xs={12}>
                                <div>
                                    <TextField
                                        placeholder="ارتفاع درب (به سانتی متر وارد شود)"
                                        fullWidth
                                        value={height}
                                        onChange={(event) => {
                                            setHeight(event.target.value)
                                        }}
                                        autoComplete="off"
                                        variant="outlined"/>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div>
                                    <TextField
                                        placeholder="قیمت هر مترمربع"
                                        fullWidth
                                        type={"number"}
                                        value={price}
                                        onChange={(event) => {
                                            setPrice(event.target.value)
                                        }}
                                        autoComplete="off"
                                        variant="outlined"/>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    sx={{marginBottom: 1}}
                                    fullWidth
                                    onClick={handle_btn_addtofactor}
                                    color="success"
                                    variant="contained">افزودن دهنه</Button>
                            </Grid>
                        </Grid>

                        <div>
                            <Grid container sx={{marginBottom: 1}} spacing={2}>
                                <Grid item xs={6}>
                                    <Button
                                        sx={{marginBottom: 2}}
                                        onClick={()=>setStep(step+1)}
                                        variant="contained">
                                        <ArrowForwardIcon/>
                                        {" مرحله بعدی "}
                                    </Button>
                                </Grid>
                                <Grid item xs={6} textAlign={"left"}>
                                    <Button
                                        dir={"ltr"}
                                        sx={{marginBottom: 2}}
                                        onClick={()=>setStep(step-1)}
                                        variant="contained">
                                        <ArrowBackIcon/>
                                        {" مرحله قبلی "}
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div id={"step3"} style={{display: step===3 ? "" : "none"}}>
                        <Grid container
                              sx={{width: "100%", marginBottom: 1, marginTop: 1, backgroundColor: "rgba(232,232,232,0.62)"}}
                              spacing={2}>
                            <Grid item xs={4}>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={handle_change_checkremote}
                                                checked={checkremote}
                                                sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                            />
                                        }
                                        sx={!checkremote ? {color: "#c0c0c0"} : {color: "#000000"}}
                                        label="ریموت"/>
                                </div>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    id="outlined-basic"
                                    placeholder="استاندارد تعداد ریموت ها 3 عدد"
                                    disabled={!checkremote}
                                    value={txtRemote}
                                    variant="outlined"
                                    type="number"
                                    sx={{fontSize: "8px"}}
                                    onChange={(event) => {
                                        setTxtRemote(event.target.value)
                                    }}
                                    autoComplete="off"
                                    fullWidth
                                    size={"small"}/>
                            </Grid>
                            <div style={{display: "flex", justifyContent: "right", marginBottom: 10, marginRight: 30}}>
                                <Typography
                                    sx={{fontSize: "12px", color: "#8a8a8a"}}
                                ></Typography>
                            </div>
                        </Grid>
                        <Grid container
                              sx={{width: "100%", marginBottom: 1, marginTop: 1, backgroundColor: "rgba(232,232,232,0.62)"}}
                              spacing={2}>
                            <Grid item xs={4}>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={handle_change_checkwireless}
                                                checked={checkwirelesskey}
                                                sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                            />
                                        }
                                        sx={!checkwirelesskey ? {color: "#c0c0c0"} : {color: "#000000"}}
                                        label="کلید وایرلس"/>
                                </div>
                            </Grid>
                            <Grid item xs={8}>
                                <div>
                                    <TextField
                                        id="outlined-basic"
                                        placeholder="استاندارد تعداد کلید وایرلس ها 1 عدد"
                                        disabled={!checkwirelesskey}
                                        value={txtWirelessKey}
                                        variant="outlined"
                                        autoComplete="off"
                                        onChange={(event) => {
                                            setTxtWirelessKey(event.target.value)
                                        }}
                                        fullWidth
                                        size={"small"}
                                    />
                                </div>
                            </Grid>
                            <div style={{display: "flex", justifyContent: "right", marginBottom: 10, marginRight: 30}}>
                                <Typography
                                    sx={{fontSize: "12px", color: "#8a8a8a"}}
                                ></Typography>
                            </div>
                        </Grid>
                        <Grid container
                              sx={{width: "100%", marginBottom: 1, marginTop: 1, backgroundColor: "rgba(232,232,232,0.62)"}}
                              spacing={2}>
                            <Grid item xs={4}>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={handle_change_checkmotorcover}
                                                checked={checkmotorcover}
                                                sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                            />
                                        }
                                        sx={!checkmotorcover ? {color: "#c0c0c0"} : {color: "#000000"}}
                                        label="کاور موتور"/>
                                </div>
                            </Grid>
                            <Grid item xs={8}>
                                <div>
                                    <TextField
                                        id="outlined-basic"
                                        placeholder="تعداد"
                                        disabled={!checkmotorcover}
                                        variant="outlined"
                                        value={txtMotorCover}
                                        autoComplete="off"
                                        onChange={(event) => {
                                            setTxtMotorCover(event.target.value)
                                        }}
                                        fullWidth
                                        size={"small"}
                                    />
                                </div>
                            </Grid>
                            <div style={{display: "flex", justifyContent: "right", marginBottom: 10, marginRight: 30}}>
                                <Typography
                                    sx={{fontSize: "12px", color: "#8a8a8a"}}
                                ></Typography>
                            </div>
                        </Grid>
                        <Grid container
                              sx={{width: "100%", marginBottom: 1, marginTop: 1, backgroundColor: "rgba(232,232,232,0.62)"}}
                              spacing={2}>
                            <Grid item xs={4}>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={handle_change_checkmotor}
                                                checked={checkmotor}
                                                sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                            />
                                        }
                                        sx={!checkmotor ? {color: "#c0c0c0"} : {color: "#000000"}}
                                        label="موتور"/>
                                </div>
                            </Grid>
                            <div style={{display: "flex", justifyContent: "right", marginBottom: 10, marginRight: 4}}>
                                <RadioGroup
                                    row
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 18,
                                        },
                                        fontSize: 18,
                                    }}>
                                    <FormControlLabel
                                        onChange={(event) => {
                                            setTxtMotor(event.target.value)
                                        }}
                                        disabled={!checkmotor}
                                        checked={txtMotor === '300'}
                                        value="300" control={<Radio/>} label="300 کیلو"/>
                                    <FormControlLabel
                                        onChange={(event) => {
                                            setTxtMotor(event.target.value)
                                        }}
                                        disabled={!checkmotor}
                                        checked={txtMotor === '400'}
                                        value="400" control={<Radio/>} label="400 کیلو"/>
                                    <FormControlLabel
                                        onChange={(event) => {
                                            setTxtMotor(event.target.value)
                                        }}
                                        disabled={!checkmotor}
                                        checked={txtMotor === '600'}
                                        value="600" control={<Radio/>} label="600 کیلو"/>
                                    <FormControlLabel
                                        onChange={(event) => {
                                            setTxtMotor(event.target.value)
                                        }}
                                        disabled={!checkmotor}
                                        checked={txtMotor === '700'}
                                        value="700" control={<Radio/>} label="700 کیلو"/>
                                    <FormControlLabel
                                        onChange={(event) => {
                                            setTxtMotor(event.target.value)
                                        }}
                                        disabled={!checkmotor}
                                        checked={txtMotor === '800'}
                                        value="800" control={<Radio/>} label="800 کیلو"/>
                                    <FormControlLabel
                                        onChange={(event) => {
                                            setTxtMotor(event.target.value)
                                        }}
                                        disabled={!checkmotor}
                                        checked={txtMotor === 'توپلار'}
                                        value="توپلار" control={<Radio/>} label="توپلار"/>
                                </RadioGroup>

                            </div>
                        </Grid>
                        <Grid container
                              sx={{width: "100%", marginBottom: 1, marginTop: 1, backgroundColor: "rgba(232,232,232,0.62)"}}
                              spacing={2}>
                            <Grid item xs={4}>
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={handle_change_checkgsmreciver}
                                                checked={checkgsmreciver}
                                                sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}
                                            />
                                        }
                                        sx={!checkgsmreciver ? {color: "#c0c0c0"} : {color: "#000000"}}
                                        label="gsm receiver"/>
                                </div>
                            </Grid>
                            <Grid item xs={8}>
                                <div>
                                    <TextField
                                        id="outlined-basic"
                                        placeholder="تعداد"
                                        disabled={!checkgsmreciver}
                                        value={txtGsm}
                                        variant="outlined"
                                        autoComplete="off"
                                        onChange={(event) => {
                                            setTxtGsm(event.target.value)
                                        }}
                                        fullWidth
                                        size={"small"}
                                    />
                                </div>
                            </Grid>
                            <div style={{display: "flex", justifyContent: "right", marginBottom: 10, marginRight: 30}}>
                                <Typography
                                    sx={{fontSize: "12px", color: "#8a8a8a"}}>
                                    اگر می خواهید درب اتوماتیک شما از طریق تلفن همراه تان با ارسال اس ام اس یا تماس باز شود
                                    این گزینه را انتخاب کنید
                                </Typography>
                            </div>
                        </Grid>
                        <Grid container
                              sx={{width: "100%", marginBottom: 1, marginTop: 1, backgroundColor: "rgba(232,232,232,0.62)"}}
                              spacing={2}>
                            <Grid item xs={4}>
                                <div style={{marginRight: "20px"}}>
                                    سنسور
                                </div>
                            </Grid>
                            <Grid item xs={8}>
                                <div>
                                    2
                                </div>
                            </Grid>
                            <div style={{display: "flex", justifyContent: "right", marginBottom: 10, marginRight: 30}}>
                                <Typography
                                    sx={{fontSize: "12px", color: "#8a8a8a"}}
                                >این مقدار ثابت می باشد و در فاکتور شما لحاظ می شود</Typography>
                            </div>
                        </Grid>
                        <Grid container sx={{marginBottom: 1, direction: "rtl"}} spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    sx={{marginBottom: 2}}
                                    onClick={handle_btn_createpdf}
                                    color={"success"}
                                    variant="contained">صدور پیش فاکتور</Button>
                            </Grid>
                        </Grid>
                        <div>
                            <Grid container sx={{marginBottom: 1}} spacing={2}>
                                <Grid item xs={6}>
                                    <Button
                                        sx={{marginBottom: 2}}
                                        disabled
                                        variant="contained">
                                        <ArrowForwardIcon/>
                                        {" مرحله بعدی "}
                                    </Button>
                                </Grid>
                                <Grid item xs={6} textAlign={"left"}>
                                    <Button
                                        dir={"ltr"}
                                        sx={{marginBottom: 2}}
                                        onClick={()=>setStep(step-1)}
                                        variant="contained">
                                        <ArrowBackIcon/>
                                        {" مرحله قبلی "}
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>

                </Paper>
            </Box>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin: 2}}/>
            </Dialog>
        </div>
    )
}
export default EditInvoice;