import React,{useState,useEffect} from "react";
import {Navigate} from "react-router-dom";
import ErrorMsg from "./msg/ErrorMsg";
import SuccsMsg from "./msg/SuccsMsg";
import Menu from "./Menu";
import {
    Box,
    CircularProgress,
    Dialog,
    Paper, styled,
    Table,
    TableBody, TableCell, tableCellClasses,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import BorderColorIcon from "@mui/icons-material/BorderColor";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const FactorList=()=>{
    const [errorMsg, setErrorMsg] = useState("");
    const [succsMsg, setSuccsMsg] = useState("");
    const [loading, setLoading] = useState(true);
    const [factorList , setFactorList] = useState([]);

    useEffect(()=>{
        setLoading(true);
        const data = {
            token: localStorage.getItem("logintoken"),
            type: "marketer",
        }
        axios.post("https://app.abuomarco.com/api/get_factorlist.php", data)
            .then(response => {
                setLoading(false)
                if (response.status === 200) {
                    if (response.data.error === false){
                        setFactorList(response.data.data);
                    }
                }
            })
            .catch(error => {
                setLoading(false);
            })
    },[])
    return(
        <div id="main">
            {localStorage.getItem("logintoken") ? '' : <Navigate replace to="/login"/>}
            {errorMsg !== '' ? <ErrorMsg message={errorMsg} openn={true} seterrormsg={setErrorMsg}/> : ''}
            {succsMsg !== '' ? <SuccsMsg message={succsMsg} openn={true} setsuccsmsg={setSuccsMsg}/> : ''}
            <Menu/>
            <Box
                sx={{
                    margin: 2,
                    marginBottom: 2,
                    direction: "rtl",
                }}>
                <Paper elevation={3}>
                    <TableContainer>
                        <Table
                            sx={{maxHeight:'80%',overflowY:'auto'}}
                        >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="right">شماره فاکتور</StyledTableCell>
                                    <StyledTableCell align="right">نام مشتری</StyledTableCell>
                                    <StyledTableCell align="right">شماره تماس</StyledTableCell>
                                    <StyledTableCell align="right">تاریخ</StyledTableCell>
                                    <StyledTableCell align="right">وضعیت</StyledTableCell>
                                    <StyledTableCell align="right">دانلود</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            {factorList !== "" ?
                                <TableBody>
                                    {factorList.map(list => (
                                        <StyledTableRow key={list.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <StyledTableCell align="right">
                                                {list.number}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row" align="right">
                                                {list.customername}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row" align="right">
                                                {list.customertel}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row" align="right">
                                                {list.date}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row" align="right">
                                                {list.adminverify==="0" ? "تکمیل نشده" : ""}
                                                {list.adminverify==="1" ? "در انتظار تایید" : ""}
                                                {list.adminverify==="2" ? "تایید شده" : ""}
                                                {list.adminverify==="3" ? "رد شده" : ""}
                                            </StyledTableCell>
                                            <StyledTableCell align="right" sx={{width:"100px"}}>
                                                {list.adminverify==="2" ? <a href={"https://app.abuomarco.com/pdf/index.php?fnumber=" + list.number}>دانلود PDF</a> : ""}
                                            </StyledTableCell>
                                        </StyledTableRow >
                                    ))}
                                </TableBody>
                                : '' }

                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
            <Dialog
                open={loading}
                scroll="paper">
                <CircularProgress sx={{margin: 2}}/>
            </Dialog>
        </div>
    )
}
export default FactorList;
